/* FormGroup */
/*  Navlayout  */
/* FormGroup */
/*  Navlayout  */
.u-form-control {
  position: relative;
  display: inline-block;
  padding: 0 12px;
  width: 100%;
  height: 32px;
  cursor: text;
  font-size: 14px;
  line-height: 1.5;
  color: #424242;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgb(165, 173, 186);
  border-radius: 3px;
  transition: all .3s; }
  .u-form-control:focus {
    border-color: #66afe9;
    outline: 0; }
  .u-form-control::-ms-clear, .u-form-control ::-ms-reveal {
    display: none; }

.u-form-control[disabled] {
  background: #F7F9FB;
  border-color: #DFE1E6;
  color: #909090;
  cursor: not-allowed; }

.u-form-control.lg {
  height: 40px;
  font-size: 14px; }

.u-form-control.sm {
  font-size: 12px;
  height: 26px; }

.u-form-control-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  padding: 4px;
  width: 100%; }
  .u-form-control-search-wrapper .u-form-control-search-action {
    color: #ccc;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    text-decoration: none; }
    .u-form-control-search-wrapper .u-form-control-search-action .uf {
      transition: all .3s;
      font-size: 12px;
      color: #ccc; }
      .u-form-control-search-wrapper .u-form-control-search-action .uf.uf-search:before {
        content: "\e603"; }

.u-form-control-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; }
  .u-form-control-affix-wrapper .u-form-control-prefix, .u-form-control-affix-wrapper .u-form-control-suffix {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    line-height: 0;
    right: 7px;
    color: rgba(0, 0, 0, 0.65); }

/* FormGroup */
/*  Navlayout  */
/* FormGroup */
/*  Navlayout  */
.u-input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #505F79;
  text-align: center;
  background-color: #eee;
  border: 1px solid rgb(165, 173, 186);
  border-radius: 3px;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell; }
  .u-input-group-addon:last-child {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .u-input-group-addon:first-child {
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }

/* FormGroup */
/*  Navlayout  */
.u-input-group-addon {
  font-size: 14px; }

.u-input-group-btn {
  font-size: 0;
  white-space: nowrap;
  position: relative;
  width: 1%;
  vertical-align: middle;
  display: table-cell; }
  .u-input-group-btn .u-button {
    position: relative; }

.u-input-group-btn:first-child > button, .u-input-group-btn:first-child > .btn-group > button, .u-input-group-btn:first-child > .dropdown-toggle, .u-input-group-btn:last-child > .btn-group:not(:last-child) > button, .u-input-group-btn:last-child > button:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.u-input-group-btn:first-child > .btn-group:not(:first-child) > button, .u-input-group-btn:first-child > button:not(:first-child), .u-input-group-btn:last-child > button, .u-input-group-btn:last-child > .btn-group > button, .u-input-group-btn:last-child > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.u-input-group-btn:last-child > button, .u-input-group-btn:last-child > .btn-group {
  margin-left: -1px; }

.u-input-group-btn:first-child > button, .u-input-group-btn:first-child > .btn-group {
  margin-right: -1px; }

.u-input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  .u-input-group .u-input-group-btn .u-button {
    border: 1px solid rgb(165, 173, 186);
    height: 32px;
    line-height: 1.3; }
  .u-input-group .u-form-control {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell; }
    .u-input-group .u-form-control:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
    .u-input-group .u-form-control:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
    .u-input-group .u-form-control:not(:first-child):not(:last-child) {
      border-radius: 0; }
  .u-input-group.simple .u-form-control {
    border-radius: 3px; }
  .u-input-group.simple .u-input-group-btn {
    position: absolute;
    top: 4px;
    bottom: 0;
    right: 6px;
    min-width: 20px; }
  .u-input-group.simple .u-form-control:not(:last-child) {
    padding-right: 23px; }

.u-input-number {
  width: 100%;
  border: 1px solid rgb(165, 173, 186);
  border-radius: 3px; }
  .u-input-number-out {
    position: relative; }
  .u-input-number-placeholder {
    color: #757575;
    position: absolute;
    z-index: 2;
    font-size: 12px;
    width: calc(100% - 34px);
    white-space: nowrap;
    overflow: hidden; }
  .u-input-number.u-input-group.simple .u-input-number-placeholder {
    left: 12px;
    top: 8px; }
  .u-input-number.u-input-number-sm .u-input-number-placeholder {
    left: 31px;
    top: 4px;
    width: calc(100% - 63px);
    text-align: center; }
  .u-input-number.u-input-number-sm.u-input-group.simple .u-input-group-btn .icon-group {
    height: 26px; }
  .u-input-number.u-input-number-sm.u-input-group.simple .u-input-number-placeholder {
    top: 4px;
    text-align: left;
    width: auto;
    max-width: calc(100% - 63px); }
  .u-input-number.u-input-number-lg .u-input-number-placeholder {
    left: 31px;
    top: 12px;
    width: calc(100% - 63px);
    text-align: center; }
  .u-input-number.u-input-number-lg.u-input-group.simple .u-input-group-btn .icon-group {
    height: 40px; }
  .u-input-number.u-input-number-lg.u-input-group.simple .u-input-number-placeholder {
    top: 12px;
    text-align: left;
    width: auto;
    max-width: calc(100% - 63px); }
  .u-input-number.u-input-group {
    border-spacing: 0; }
  .u-input-number.u-input-group > span:first-child {
    border-right: 1px solid rgb(165, 173, 186); }
  .u-input-number.u-input-group > span:last-child {
    border-left: 1px solid rgb(165, 173, 186);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .u-input-number.disabled-con > span:first-child {
    border-right: 1px solid #dfe1e6; }
  .u-input-number.disabled-con > span:last-child {
    border-left: 1px solid #dfe1e6; }
  .u-input-number.u-input-group > span {
    background: #fff; }
  .u-input-number.u-input-group .u-input-group-addon:nth-of-type(1) {
    border-right: 1px solid rgb(165, 173, 186);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .u-input-number.u-input-group > span:hover {
    background: rgb(235, 236, 240); }
  .u-input-number.u-input-group.simple .u-input-group-btn {
    position: relative;
    top: 0px;
    z-index: 2;
    right: 0;
    width: 22px; }
    .u-input-number.u-input-group.simple .u-input-group-btn .icon-group {
      height: 32px;
      background: #fff;
      border-radius: 0 3px 3px 0;
      -webkit-transition: opacity .24s linear .1s;
      transition: opacity .24s linear .1s; }
      .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .plus {
        position: relative;
        display: block;
        width: 100%;
        text-align: center;
        line-height: 0;
        height: 50%;
        overflow: hidden;
        color: #505F79;
        -webkit-transition: all .1s linear;
        transition: all .1s linear;
        cursor: pointer;
        border-top-right-radius: 3px; }
        .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .plus.disabled {
          cursor: not-allowed;
          background: #fff !important;
          color: #C1C7D0; }
        .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .plus.disabled-cursor {
          background: #f7f9fb;
          cursor: not-allowed;
          border-top-right-radius: 3px; }
          .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .plus.disabled-cursor:hover {
            background: #f7f9fb; }
        .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .plus:hover {
          background: rgb(235, 236, 240); }
      .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .reduce {
        position: relative;
        top: -1px;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid rgb(165, 173, 186);
        border-bottom-right-radius: 3px;
        line-height: 0;
        height: 50%;
        box-sizing: initial;
        overflow: hidden;
        color: #505F79;
        -webkit-transition: all .1s linear;
        transition: all .1s linear;
        cursor: pointer; }
        .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .reduce.disabled {
          cursor: not-allowed;
          background: #fff !important;
          color: #C1C7D0; }
        .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .reduce.disabled-cursor {
          cursor: not-allowed;
          background: #f7f9fb;
          border-bottom-right-radius: 3px;
          border-top: 1px solid #DFE1E6; }
          .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .reduce.disabled-cursor:hover {
            background: #f7f9fb; }
        .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .reduce:hover {
          background: rgb(235, 236, 240); }
      .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .disabled-cursor.disabled {
        background: none; }
      .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .disabled-cursor .uf {
        color: #C1C7D0; }
        .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .disabled-cursor .uf:hover {
          color: #ccc; }
      .u-input-number.u-input-group.simple .u-input-group-btn .icon-group .uf {
        font-size: 12px;
        line-height: 16px; }
  .u-input-number.u-input-group.simple .u-input-group-btn[disabled] {
    background: #f7f9fb; }
  .u-input-number.u-input-group.simple .u-form-control {
    text-align: left;
    width: 100%;
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 12px; }
  .u-input-number.u-input-group.simple .u-form-control[disabled] {
    border-color: rgb(165, 173, 186); }
  .u-input-number.u-input-group.simple .u-form-control[disabled] + .u-input-group-btn .icon-group {
    border-color: rgb(165, 173, 186); }
  .u-input-number.u-input-group .u-form-control {
    width: 100%;
    text-align: center;
    border: transparent; }
  .u-input-number.u-input-group .u-form-control[disabled] {
    border-top-color: rgb(165, 173, 186);
    border-bottom-color: rgb(165, 173, 186); }
  .u-input-number.u-input-group .u-form-control:focus {
    border-color: #66afe9; }
  .u-input-number .u-input-group-addon {
    position: relative;
    cursor: pointer;
    padding: 6px;
    user-select: none;
    -moz-user-select: none;
    background: transparent;
    border: none; }
    .u-input-number .u-input-group-addon.disabled {
      cursor: not-allowed;
      color: #C1C7D0;
      background: #fff !important; }
    .u-input-number .u-input-group-addon.disabled-cursor {
      cursor: not-allowed;
      background: #f7f9fb !important;
      color: #ccc; }
  .u-input-number .u-input-number-auto .u-form-control {
    width: 100%; }
  .u-input-number .u-form-control::-webkit-outer-spin-button,
  .u-input-number .u-form-control::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .u-input-number input[type="number"] {
    -moz-appearance: textfield; }

.u-input-number-out .u-input-number.u-input-number-sm.u-input-group.simple .u-input-group-btn {
  top: 0px; }

.u-input-number-out .u-input-number.u-input-number-lg.u-input-group.simple .u-input-group-btn {
  top: 0px; }

.u-input-number-group .u-input-number-out {
  width: calc( 50% - 10px);
  display: inline-block; }

.u-input-number-group-split {
  width: 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  top: -10px; }

.u-table .u-table-tbody .u-table-row .u-input-number .u-input-group-addon:first-of-type {
  position: relative;
  left: 2px; }

.u-table .u-table-tbody .u-table-row .u-input-number .u-input-group-addon:last-of-type {
  position: relative;
  right: 2px; }

.u-table .u-table-tbody .u-table-row .u-input-number.u-input-group.simple .u-input-group-btn {
  right: 2px; }

.disabled-con,
.disabled-con .disabled-cursor > span:first-child,
.disabled-con .disabled-cursor > span:last-child {
  border-color: #dfe1e6; }
